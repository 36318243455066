import React from 'react'
import Video1 from '../img/v2.mp4'
import Video2 from '../img/Steli2.mp4'

export const WhyUs = () => {
  return (
    <div id='whyus' className='container px-10 xl:px-20 mx-auto mt-20 md:mt-32 xl:mt-32' >
        <div className='container items-center px-5 mb-20 md:px-20 xl:px-60 mx-auto' >
            <h2 className='text-4xl text-left md:text-center lg:text-5xl font-Quattrocento'>De ce să alegi acest masaj?</h2>
            <p className=' mt-5 text-left md:text-center text-lg xl:text-xl text-black font-Quicksand'>Descoperă, prin cele două clipuri video explicative, cum acest masaj te ajută să atingi un echilibru perfect între relaxare și revitalizare, oferindu-ți o stare de bine de durată.</p>
        </div>
        <div className='flex flex-col items-center align-middle'>
            <div className='hidden lg:flex md:gap-32 mt-10 md:mt-0'>
                  <video width="380" height="500" controls  frameborder="0">
                      <source src={Video1} type="video/mp4"/>
                  </video>
                  <video width="380" height="500" controls  frameborder="0">
                      <source src={Video2} type="video/mp4"/>
                  </video>
            </div>
        </div>

            <div className='lg:w-1/2'>
                  <div className='flex lg:hidden justify-center lg:w-1/2 mt-10 lg:mt-0'>
                    <video width="286" height="500" controls  frameborder="0">
                      <source src={Video2} type="video/mp4"/>
                  </video>
                  </div>
                  <div className='flex lg:hidden justify-center lg:w-1/2 mt-10 lg:mt-0'>
                    <video width="286" height="500" controls  frameborder="0">
                      <source src={Video2} type="video/mp4"/>
                  </video>
                  </div>
            </div>
    </div>
  )
}
