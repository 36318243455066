import React from 'react';
import '../index.css';
import { Link } from 'react-scroll';
import Background from '../img/bg.webp'
import BackgroundPhone from '../img/bg-phone.webp'
import BackgroundPhone2 from '../img/bg-phone-2.webp'

const HeroSection = () => {
  return (
    <div id='Acasa' className="container flex rounded-3xl md:bg-none flex-col-reverse md:flex-row items-center px-10 xl:px-20 mx-auto pt-5 md:pt-2 xl:pt-10 space-y-0">
        
        <div className=' absolute top-0 -right-10 lg:right-20 -z-10'>
          <img src={Background} alt="O femeie care zâmbește în timp ce îți face un tratament facial cu o mască albă pe un fundal roz și verde" className='hidden md:block md:h-[550px] md:w-auto xl:h-auto ' />
        </div>
        <div className='flex flex-col mb-32 space-y-6 md:w-1/2'>
            <h1 className="max-w-xl text-4xl xl:leading-tight text-black md:text-black md:text-5xl lg:text-6xl xl:text-7xl md:text-left font-Quattrocento"><span className=' text-accent-coral-pink md:text-black'>Experiența Unică</span> a Masajului Facial Sculptural Bucal</h1>
            <p className='max-w-lg text-left text-lg lg:text-xl md:text-left text-black md:text-black font-Quicksand'>Descoperă arta transformării feței tale prin tehnici avansate de masaj facial sculptural bucal. Eliberează-ți frumusețea interioară și redescoperă strălucirea și vitalitatea pielii tale.</p>
            <div className='flex flex-row h-[100px] space-x-5  md:hidden'>
              <div>
                <img src={BackgroundPhone} alt="O femeie care zâmbește în timp ce îți face un tratament facial cu o mască albă" className=' ' />
              </div>
              <div>
                <img src={BackgroundPhone2} alt="Mâinile unei femei care ține o cremă verde deschis" className=' ' />
              </div>
            </div>
            <div className='flex justify-center md:justify-start'>
                <div className="flex flex-row font-Quicksand mt-0 md:mt-10">
                <Link to="Contact" spy={true} smooth={true} offset={-100} duration={500} className='hover:cursor-pointer text-center lg:text-left lg:text-lg xl:text-xl p-3 xl:p-5 md:px-6 pt-2 text-white bg-accent-coral-pink rounded-full baseline md:hover:text-accent-coral-pink md:hover:bg-white md:hover:border-2 hover:border-accent-coral-pink '>Programează un masaj</Link>
                <Link to="Beneficii" spy={true} smooth={true} offset={-100} duration={500} className='hover:cursor-pointer text-center lg:text-left p-0 ml-2 lg:text-lg xl:text-xl px-6 pt-2 lg:pt-4 text-black md:text-accent-coral-pink md:hover:border-2 md:hover:rounded-full'>Află mai multe</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HeroSection