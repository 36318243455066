import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/Hero";
import Benefits from "./components/Benefits";
import BenefitsSecond from "./components/BenefitsSecond";
import Results from "./components/Results";
import About from './components/About';
import Faq from "./components/Faq";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import CookieBanner from "./components/CookieBanner";
import Testimonials from "./components/Testimonials";
import { WhyUs } from "./components/WhyUs";
import './index.css'

function App() {
  
  return (
    <div>
      <Navbar/>
      <HeroSection/>
      <Benefits/>
      <BenefitsSecond/>
      <Results/>
      <WhyUs/>
      <Testimonials/>
      <About/>
      <Services/>
      <Contact/>
      <Faq/>
      <CookieBanner/>
      <Footer/>
    </div>
  );
}

export default App;
